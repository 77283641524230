const BG_COMBINATION_1 = "bg-combination-1";
const BG_COMBINATION_2 = "bg-combination-2";
const BG_CONTAINER = "bg-container";

export function toggleBGClass() {
  var element = document.getElementById(BG_CONTAINER);
  if (element.className === BG_COMBINATION_1) {
    element.className = BG_COMBINATION_2;
  } else {
    element.className = BG_COMBINATION_1;
  }
}
