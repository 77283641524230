import React from "react";
import { Spin, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function LoadingOrChildren({ loading, children }) {
  if (loading) {
    return (
      <div className="__LOADING__">
        <Row justify="center" align="bottom">
          <Col span="24">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ margin: "50px 0", color: "#ec6173", fontSize: 50 }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
  return children;
}
