import { connect } from "react-redux";
import { changeDate } from "../actions";
import Calendar from "../Components/Calendar";

const mapStateToProps = state => {
  return {
    viewingDate: state.date.viewingDate
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleChangeDate: date => dispatch(changeDate(date))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
