import React from "react";
import { Layout, Row, Col } from "antd";

const { Content } = Layout;

export function LayoutWithSidebar({ children, sidebar }) {
  return (
    <Content>
      <Row>
        <Col md={24} lg={16}>
          {children}
        </Col>
        <Col push="1" md={23} lg={7}>
          <div className="hide-on-small-screens sidebar">{sidebar}</div>
        </Col>
      </Row>
    </Content>
  );
}
