import React, { useState, useEffect } from "react";
import { Row, Col, notification } from "antd";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LayoutWithSidebar } from "../Components/Layout";
import DateNavigationContainer from "../Containers/DateNavigationContainer";
import CalendarContainer from "../Containers/CalendarContainer";
import Config from "../Config";
import EventItem from "../Components/EventItem";
import LoadingOrChildren from "../Components/LoadingOrChildren";
import ReactGA from "react-ga";
import http from "../services/http";
import translate from '../Utils/translate';

export default function EventPage() {
  let { eventId } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await http.get(`/events`, {
          params: {
            key: Config.API_KEY,
            uid: [eventId],
            detailed: 1,
          },
        });
        setEvent(response.data.events[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error({
          message: t("messages.error"),
        });
      }
    }
    setLoading(true);
    fetchData();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [eventId, i18n.language, t]);

  useEffect(() => {
    if (event.title) {
      document.title = `${translate(event.title, i18n.language)} - ${Config.name}`;
    }
  }, [event.title, i18n.language]);

  return (
    <LayoutWithSidebar sidebar={<CalendarContainer />}>
      <div className="__EVENTS__">
        <div className="events-navigation">
          <DateNavigationContainer dateFormat={Config.dateNavigationFormat} />
        </div>
        <div className="__EVENT__">
          <LoadingOrChildren loading={loading}>
            <Row gutter={{ xs: 0, md: 16, xl: 16, lg: 16 }}>
              <Col span="24">
                <EventItem detail event={event} />
              </Col>
            </Row>
          </LoadingOrChildren>
        </div>
      </div>
    </LayoutWithSidebar>
  );
}
