import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout, Button } from "antd";
import ReactGA from "react-ga";
import { CloseOutlined } from "@ant-design/icons";
import classnames from "classnames";

import "./App.scss";
import EventList from "./Pages/Events";
import EventDetail from "./Pages/Event";
import { Header } from "./Components/Header";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Footer from "./Components/Footer";

ReactGA.initialize("UA-143832571-2", { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  const [showFooter, setShowFooter] = useState(false);
  const className = classnames("footer-link", {
    "footer-link-full": showFooter
  });
  return (
    <div id="bg-container" className="bg-combination-1">
      <Router>
        <Header />
        <div className="mainContainer">
          <Layout className="main-container-layout">
            <Layout>
              <Switch>
                <Route path="/events/:eventId">
                  <EventDetail />
                </Route>
                <Route path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/">
                  <EventList />
                </Route>
              </Switch>
            </Layout>
          </Layout>
          <div className="hide-on-small-screens">
            <div className="footer">
              <div className="footer-wrapper">
                <div className="footer-content">{showFooter && <Footer />}</div>
                <div className={className}>
                  <Button
                    icon={showFooter ? <CloseOutlined /> : null}
                    onClick={() => setShowFooter(!showFooter)}
                  >
                    {showFooter ? null : "à propos"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}
