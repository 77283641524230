import axios from 'axios';
import qs from 'qs';
import Config from '../Config';

const http = axios.create({
  timeout: 60000,
  baseURL: Config.API_SERVER,
  paramsSerializer: function (params) {
    let serializer = qs.stringify(params, {arrayFormat: 'brackets'});
    return serializer;
  },
  params: {
    key: Config.API_KEY,
  },
});

http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
export default http;
