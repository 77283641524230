import React, { Component } from "react";
import moment from "moment";
import { Button } from "antd";
import range from "lodash.range";
import classnames from "classnames";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

import DateNavigation from "./DateNavigation";
import { nextMonth, previousMonth } from "./../Utils/dates";
import Config from "../Config";

function Days() {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="day name">{i18n.language === "fr" ? "L" : "M"}</div>
      <div className="day name">{i18n.language === "fr" ? "M" : "T"}</div>
      <div className="day name">{i18n.language === "fr" ? "M" : "W"}</div>
      <div className="day name">{i18n.language === "fr" ? "J" : "T"}</div>
      <div className="day name">{i18n.language === "fr" ? "V" : "F"}</div>
      <div className="day name">{i18n.language === "fr" ? "S" : "S"}</div>
      <div className="day name">{i18n.language === "fr" ? "D" : "S"}</div>
    </>
  );
}

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewingDate: props.viewingDate
    };
  }

  setDate(viewingDate) {
    this.setState({
      viewingDate
    });
  }

  static getStartOfTheMonth(date) {
    return moment(date).startOf("month");
  }

  static getEndOfTheMonth(date) {
    return moment(date).endOf("month");
  }

  formatDateForChange(day) {
    try {
      if (parseInt(day) < 10) {
        day = `0${day}`;
      }
    } catch (error) {}

    const { viewingDate } = this.state;
    const currentMonth = moment(viewingDate).format("MM");
    const currentYear = moment(viewingDate).format("YYYY");
    return moment(`${currentYear}-${currentMonth}-${day}`).format(
      Config.dateFormat
    );
  }

  renderEmptyDay(key) {
    return <div key={key} className="day day-empty" />;
  }

  handleDate(date) {
    const { handleChangeDate, location, history } = this.props;
    handleChangeDate(date);

    if (location.pathname.includes("events")) {
      history.push("/");
    }
  }

  renderDay(day) {
    const { viewingDate } = this.props;
    const clickDate = this.formatDateForChange(day);
    const disabled = viewingDate === clickDate;
    const classes = classnames("day", {
      "day-disabled": viewingDate === clickDate
    });
    return (
      <div key={`${clickDate}`} className={classes}>
        <Button
          disabled={disabled}
          type="text"
          onClick={() => this.handleDate(clickDate)}
        >
          {day}
        </Button>
      </div>
    );
  }

  render() {
    const { viewingDate } = this.state;
    const { sidebar } = this.props;

    const startingDayOfTheMonth = Calendar.getStartOfTheMonth(
      viewingDate
    ).isoWeekday();

    const endingDayOfTheMonth = Calendar.getEndOfTheMonth(viewingDate).date();
    const calendarClassNames = classnames("__CALENDAR__", { sidebar: sidebar });
    return (
      <div className={calendarClassNames}>
        <div className="calendar-wrapper">
          <div className="calendar-navigation">
            <DateNavigation
              viewingDate={viewingDate}
              handleChangeNextDate={() => this.setDate(nextMonth(viewingDate))}
              handleChangePreviousDate={() =>
                this.setDate(previousMonth(viewingDate))
              }
              dateFormat={Config.dateCalendarFormat}
            />
          </div>
          <div className="month">
            <div className="week">
              <Days />
            </div>
            <div className="week">
              {range(1, 8).map(
                index =>
                  index < startingDayOfTheMonth
                    ? this.renderEmptyDay(index - startingDayOfTheMonth + 1)
                    : this.renderDay(index - startingDayOfTheMonth + 1)
              )}
            </div>
            <div className="week">
              {range(8, 15).map(index =>
                this.renderDay(index - startingDayOfTheMonth + 1)
              )}
            </div>
            <div className="week">
              {range(15, 22).map(index =>
                this.renderDay(index - startingDayOfTheMonth + 1)
              )}
            </div>
            <div className="week">
              {range(22, 29).map(index =>
                this.renderDay(index - startingDayOfTheMonth + 1)
              )}
            </div>
            <div className="week">
              {range(29, 36).map(
                index =>
                  index - startingDayOfTheMonth + 1 > endingDayOfTheMonth
                    ? this.renderEmptyDay(index - startingDayOfTheMonth + 1)
                    : this.renderDay(index - startingDayOfTheMonth + 1)
              )}
            </div>
            <div className="week">
              {range(36, 43).map(
                index =>
                  index - startingDayOfTheMonth + 1 > endingDayOfTheMonth
                    ? this.renderEmptyDay(index - startingDayOfTheMonth + 1)
                    : this.renderDay(index - startingDayOfTheMonth + 1)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Calendar);
