import { combineReducers } from "redux";

import { getCurrentDate } from "./Utils/dates";
import {
  RESET_DATE,
  CHANGE_DATE,
  PREVIOUS_DATE,
  NEXT_DATE,
  CHANGE_LANGUAGE_EN,
  CHANGE_LANGUAGE_FR
} from "./actionsTypes";

const dateInitialState = {
  viewingDate: getCurrentDate()
};

const dateReducer = function(state = dateInitialState, action) {
  switch (action.type) {
    case CHANGE_DATE:
    case RESET_DATE:
    case PREVIOUS_DATE:
    case NEXT_DATE:
      return Object.assign({}, state, {
        viewingDate: action.payload.date
      });
    default:
      return state;
  }
};

export const languageReducer = function(state = "fr", action) {
  switch (action.type) {
    case CHANGE_LANGUAGE_FR:
    case CHANGE_LANGUAGE_EN:
      return action.payload.language_code;
    default:
      return state;
  }
};

export default combineReducers({
  date: dateReducer,
  langauge: languageReducer
});
