import moment from "moment";

import Config from "../Config";

const DATE_FORMAT = 'YYYY-MM-DD';

function getMinAllowed() {
  return moment(Config.minDate);
}

function getMaxAllowed() {
  return moment(Config.maxDate);
}
const MAX_DATE = getMaxAllowed();
const MIN_DATE = getMinAllowed();

export function nextDay(date) {
  if (moment(date).isSameOrAfter(MAX_DATE.format(Config.dateFormat))) {
    return date;
  }
  return moment(date)
    .add(1, "day")
    .format(Config.dateFormat);
}
export function previousDay(date) {
  if (moment(date).isSameOrBefore(MIN_DATE.format(Config.dateFormat))) {
    return date;
  }
  return moment(date)
    .subtract(1, "day")
    .format(Config.dateFormat);
}

export function nextMonth(date) {
  if (moment(date).isSameOrAfter(MAX_DATE.format(Config.dateFormat), "month")) {
    return date;
  }
  return moment(date)
    .add(1, "month")
    .format(Config.dateFormat);
}

export function previousMonth(date) {
  if (
    moment(date).isSameOrBefore(MIN_DATE.format(Config.dateFormat), "month")
  ) {
    return date;
  }
  return moment(date)
    .subtract(1, "month")
    .format(Config.dateFormat);
}

export function getCurrentDate() {
  if (Config.hackCurrentDate !== null) {
    return moment(Config.hackCurrentDate).format(Config.dateFormat);
  }
  const currentDate = moment();

  if (currentDate.isBefore(MIN_DATE)) {
    return MIN_DATE.format(Config.dateFormat);
  }

  if (currentDate.isAfter(MAX_DATE)) {
    return MAX_DATE.format(Config.dateFormat);
  }
  return currentDate.format(Config.dateFormat);
}

export function isTheSameDate(date) {
  const current = moment().format(DATE_FORMAT);
  return current === date;
}

export function getNextDayDate(date) {
  const nextDay = moment(date).add(1, 'days');
  // const isValid = _validateMinMaxDate(nextDay);
  // return isValid ? nextDay.format(DATE_FORMAT) : date;
  return nextDay.format(DATE_FORMAT);
}

export function timestamp(date) {
  const isSameDate = isTheSameDate(date);
  if (isSameDate || !date) {
    return moment().toISOString();
  }
  return moment(date).toISOString();
}
