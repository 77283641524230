import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Row, Col } from "antd";

import { getNextDayDate, timestamp } from "../Utils/dates";

import { LayoutWithSidebar } from "../Components/Layout";
import DateNavigationContainer from "../Containers/DateNavigationContainer";
import LoadingOrChildren from "../Components/LoadingOrChildren";
import EventItem from "../Components/EventItem";
import CalendarContainer from "../Containers/CalendarContainer";
import Config from "../Config";

import { useInfiniteQuery } from "react-query";
import http from "../services/http";
import moment from "moment";

function EventsPage({ viewingDate }) {
  const [events, setEvents] = useState([]);
  const isNextPageAvailable = (total, page) => {
    return total > page * Config.API_RESULT_SIZE;
  };

  const {
    // data,
    // error,
    // isFetchingNextPage,
    // fetchNextPage,
    // hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["events", { viewingDate }],
    async ({ pageParam = 1 }) => {
      const res = await http.get("/events", {
        params: {
          // ...searchParams,
          key: Config.API_KEY,
          "type-devenement": [[40, 47, 86, 35, 48, 45, 43, 38, 61]],
          "thematiques-interne": [71],
          "publics-cibles": [[30]],
          timings: {
            gte: moment(viewingDate).toISOString(),
            lte: timestamp(getNextDayDate(viewingDate)),
          },
          detailed: 1,
          sort: "timings.asc",
          from: (pageParam - 1) * Config.API_RESULT_SIZE,
          size: Config.API_RESULT_SIZE,
          relative: ["current", "upcoming"],
        },
      });
      setEvents(res.data.events);
      return res.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length + 1;
        if (isNextPageAvailable(lastPage.total, nextPage)) {
          return nextPage;
        }
        return undefined;
      },
    }
  );


  useEffect(() => {
    document.title = `${Config.name}`;
  });
  // let events = data.data.events;
  return (
    <LayoutWithSidebar sidebar={<CalendarContainer />}>
      <div className="__EVENTS__">
        <div className="events-navigation">
          <DateNavigationContainer dateFormat={Config.dateNavigationFormat} />
        </div>
        <Row>
          <Col span="24">
            {/* {size === 0 && !loading && <Result title={t("messages.empty")} />} */}
            <LoadingOrChildren loading={isLoading}>
              <Row gutter={{ xs: 0, md: 0, xl: 16, lg: 16 }}>
                {events.map((event) => (
                  <Col key={`col-${event.uid}`} md={24} xs={24} lg={23} xl={12}>
                    <EventItem
                      viewingDate={viewingDate}
                      key={event.uid}
                      event={event}
                    />
                  </Col>
                ))}
              </Row>
            </LoadingOrChildren>
          </Col>
        </Row>
      </div>
    </LayoutWithSidebar>
  );
}

const mapStateToProps = (state) => {
  return {
    viewingDate: state.date.viewingDate,
  };
};

export default connect(mapStateToProps, null)(EventsPage);
