import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import messages_fr from "./translations/fr.json";
// import messages_en from "./translations/en.json";

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: 'fr',
  debug: false,
  lng: "fr",
  resources: {
    en: {
      translation: {
        signIn: 'Sign In',
        logOut: 'Log Out',
        loginLogoutExampleTitle: 'Login/Logout Redux + Sagas Example',
        progressiveImageComponent: 'Progressive Image Component',
        api: 'API',
        locale: 'I18n Locale',
        rnVectorIcons: 'RN Vector Icons',
        loginWithFacebook: 'Login with Facebook',
        rni18n: 'RN i18n',
        igniteGenerated: 'Ignite Generate Screens',
        forgotPassword: 'Forgot Password',
        username: 'Username',
        password: 'Password',
        cancel: 'Cancel',
        welcome: 'Welcome',
        login: 'Login',
        tempIndicator: 'F',
        componentExamples: 'Component Examples',
        usageExamples: 'Usage Examples',
        apiTesting: 'API Testing',
        themeSettings: 'Theme Settings',
        deviceDetails: 'Device Details',
        noItems: 'No Items',
        search: 'Search',
        location: {
          PERMISSION_DENIED: 'Activate your location services.',
          POSITION_UNAVAILABLE: 'Your position is unavailable.',
          TIMEOUT: 'Your position is unavailable.',
          no_results: 'No events within 1km around me.',
        },
        generic: {
          howtogo: 'How to get there?',
          empty: 'No results.',
          loadmore: 'Load more',
          loading: 'Loading ...',
        },
        settings: {
          title: 'Informations',
          headerLang: 'Select Language',
          participation: 'In partnership with',
          support: 'With the support of',
          dev: 'Developement',
          design: 'Graphic design ',
          identity: 'Visual identity',
          pfl: 'brought to you by PFL!',
          database: 'Database',
        },
        events: {
          title: 'Events',
          facebook_event: 'Facebook Event',
          vostok: 'Radio Vostok',
          readUp: 'Read up',
          listenUp: 'Listen up',
          ticket_website: 'Tickets',
          website: 'Website',
          price: 'Price',
          organizer: 'Organizer',
          other_place: 'Other Place',
        },
        tpg: {
          toDestination: 'Destination is',
          stops: 'Stops',
          nostops: 'No stops around',
          description: 'Description tpg',
        },
        taxiphone: {
          description:
            'Since 1959, Taxiphone SA central has become a major actor in passengers transportation in Geneva. Over the years, this taxi dispatching compagny has always used the best available technical tools to fulfill the increasing demand of Geneva customers.',
        },
        places: {
          upcomingEvents: 'UPCOMING EVENTS',
          website: 'Website',
          phone: 'Telephone',
          map: 'Map',
        },
        categories: {
          title: 'Categories',
          places: 'Venues',
          nearme: 'Near me',
          atelier_et_visite: 'Workshop & Visits',
          expos: 'Exhibition',
          spectacles: 'Spectacles',
          clubbing: 'Clubbing',
          cinema: 'Cinema',
          child: 'Child-friendly',
          outdoor: 'Outdoor',
          festival: 'Festival',
          lecture: 'Reading',
          concert: 'Concert',
        },
        favorites: {
          title: 'Favorites',
          empty: "...and what's your summer going to look like?",
        },
      },
    },
    fr: {
      translation: {
        signIn: 'Se connecter',
        logOut: 'Se déconnecter',
        loginLogoutExampleTitle:
          'Connexion / Déconnexion Redux + Sagas Exemple',
        progressiveImageComponent: 'Composant Image Progressive',
        api: 'Mon Dieu!  Une API pour vous!',
        locale: 'I18n Paramètres régionaux',
        rnVectorIcons: 'RN icônes vectorielles',
        loginWithFacebook: 'Se connecter avec Facebook',
        rni18n: 'RN i18n',
        igniteGenerated: 'Ignite Générer des Écrans',
        forgotPassword: 'Mot de passe oublié',
        username: "Nom d'utilisateur",
        password: 'Mot de passe',
        cancel: 'Annuler',
        welcome: 'Bienvenue',
        login: "S'identifier",
        tempIndicator: 'C',
        componentExamples: 'Exemples de Composants',
        usageExamples: "Exemples d'utilisation",
        apiTesting: 'API Testing',
        themeSettings: 'Réglage des thèmes',
        deviceDetails: 'Détails du périphérique',
        noItems: 'Aucun',
        generic: {
          howtogo: "Comment s'y rendre?",
          empty: 'Pas de résultat.',
          loadmore: 'Load more',
          loading: 'Chargement ...',
        },
        location: {
          PERMISSION_DENIED: 'Activer vos services de localisation.',
          POSITION_UNAVAILABLE: 'Votre position est indisponible.',
          TIMEOUT: 'Votre position est indisponible.',
          no_results: 'Pas d’événement à moins d’1km autour de moi.',
        },
        settings: {
          title: 'Informations',
          headerLang: 'Choisir la langue',
          participation: 'En partenariat avec',
          support: 'Avec le soutien de',
          dev: 'Développement',
          design: 'Graphisme',
          identity: 'Identité',
          pfl: "Un projet de l'association PFL!",
          database: 'Database',
        },
        tpg: {
          toDestination: 'À destination de',
          stops: 'Arrêts',
          nostops: 'No stops around',
          description: 'Description tpg',
        },
        places: {
          upcomingEvents: 'PROCHAINS ÉVÉNEMENTS',
          website: 'Site Internet',
          phone: 'Téléphone',
          map: 'Carte',
        },
        categories: {
          title: 'Catégories',
          places: 'Lieux',
          nearme: 'Autour de moi',
          atelier_et_visite: 'Ateliers et Visites',
          expos: 'Expos',
          spectacles: 'Spectacles',
          clubbing: 'Clubbing',
          cinema: 'Cinéma',
          child: 'Enfants',
          outdoor: 'En plein air',
          festival: 'Festival',
          lecture: 'Lecture',
          concert: 'Concert',
        },
        favorites: {
          title: 'Favoris',
          empty: "...et vous, qu'allez-vous faire cet été?",
        },
        events: {
          title: 'Agenda',
          facebook_event: 'Événement Facebook',
          vostok: "L'avis de Vostok",
          readUp: 'À lire',
          listenUp: 'À écouter / À voir',
          ticket_website: 'Billetterie',
          website: 'Site Internet',
          price: 'Prix',
          organizer: 'Organisateur',
          other_place: 'Autre lieu',
        },
        taxiphone: {
          description:
            'Taxiphone, 650 taxis, 25 téléphonistes (Bilingues) qui se relaient sans relâche, 7/7 24H/24, un service technique s’occupant de la maintenance des appareils embarqués, une administration toujours présente.',
        },
      },
    },
  },
});

export default i18n;
