import React from "react";
import moment from "moment";
import { Row, Col } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import LazyLoad from "react-lazyload";

import translate from "../Utils/translate";

import Config from "../Config";

// const MAP_LINK = "https://www.google.com/maps/search/?api=1&query=";

function SocialButton({ href, text }) {
  let url = href;
  try {
    if (!/^https?:\/\//i.test(url)) {
      url = '//' + url;
    }
  } catch(error) {}

  return (
    <Col xs={24} lg={24}>
      <a
        className="ant-btn event-social-button ant-btn-primary"
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        {text}
      </a>
    </Col>
  );
}

function LinkTheEventOrNot({ eventId, viewingDate, children, link }) {
  const location = {
    pathname: `/events/${eventId}`,
    state: { date: viewingDate, eventId },
  };
  if (link) {
    return (
      <Link className="event-link" to={location}>
        {children}
      </Link>
    );
  }
  return children;
}

export default function EventItem({ viewingDate, event, detail }) {
  const { t, i18n } = useTranslation();

  const imageClasses = classnames({
    "event-image": !detail,
    "event-image-detail": detail,
  });

  let image = "";
  try {
    const base = event.image.base;
    const thumbnail = event.image.filename;
    image = `${base}${thumbnail}`;
  } catch (error) {}

  return (
    <LinkTheEventOrNot
      eventId={event.uid}
      viewingDate={viewingDate}
      link={!detail}
    >
      <div className="event-card">
        <div className={imageClasses}>
          <LazyLoad height={200} once offset={100}>
            <img src={image} alt={translate(event.title, i18n.language)} />
          </LazyLoad>
        </div>
        <div className="event-content">
          <Row>
            <Col xs={18} lg={20}>
              <h1>{translate(event.title, i18n.language)}</h1>
              {event.other_place && (
                <h3>{translate(event.other_place, i18n.language)}</h3>
              )}
              {!event.other_place && event.location.name && (
                <h3>{translate(event.location.name, i18n.language)}</h3>
              )}
            </Col>
            <Col xs={6} lg={4}>
              <div className="event-date">
                {moment(event.nextTiming.begin).format(Config.timeFormat)}
              </div>
            </Col>
          </Row>
          {detail && (
            <div className="event-detail">
              <Row>
                <Col xs={24} lg={24}>
                  <div className="event-description-container">
                    <p className="event-description">
                      {translate(
                        event["resume-geneve-en-ete"] || event.description,
                        i18n.language
                      )}
                    </p>
                    {event.conditions && event.conditions.fr && (
                      <div className="content-metadata">
                        <h4 className="sub-section">{t("events.price")}</h4>
                        <p>{translate(event.conditions, i18n.language)}</p>
                      </div>
                    )}
                    {event.organisator_url && (
                      <SocialButton
                        href={event.organisator_url}
                        text={t("events.website")}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </LinkTheEventOrNot>
  );
}
