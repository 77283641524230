import React from "react";

import { Row, Col, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { toggleBGClass } from "../Utils/bg";

const ICON_COLOR = "#3867ab";
const ICON_SIZE = 20;

export default function DateNavigation({
  handleChangeNextDate,
  handleChangePreviousDate,
  handleChangeResetDate,
  viewingDate,
  dateFormat
}) {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  let history = useHistory();
  let location = useLocation();

  return (
    <Row className="__DATE_NAVIGATION__">
      <Col span="24">
        <Row justify="center" align="middle">
          <Col flex={0}>
            <Button
              onClick={() => {
                handleChangePreviousDate(viewingDate);
                if (location.pathname.includes("events")) {
                  history.push("/");
                }
                toggleBGClass();
              }}
              type="link"
              icon={
                <LeftOutlined
                  style={{ fontSize: ICON_SIZE, color: ICON_COLOR }}
                />
              }
            />
          </Col>
          <Col flex={2}>
            <p className="currentDate">
              {handleChangeResetDate && (
                <Button
                  type="text"
                  onClick={() => {
                    handleChangeResetDate();
                    if (location.pathname.includes("events")) {
                      history.push("/");
                    }
                    toggleBGClass();
                  }}
                >
                  {moment(viewingDate).format(dateFormat)}
                </Button>
              )}
              {!handleChangeResetDate && moment(viewingDate).format(dateFormat)}
            </p>
          </Col>
          <Col flex={0}>
            <Button
              onClick={() => {
                handleChangeNextDate(viewingDate);
                if (location.pathname.includes("events")) {
                  history.push("/");
                }
                toggleBGClass();
              }}
              type="link"
              icon={
                <RightOutlined
                  style={{ fontSize: ICON_SIZE, color: ICON_COLOR }}
                />
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
