import React from "react";
import AppStoreSrc from "../Images/appstore.svg";
import GoogleStoreSrc from "../Images/playstore.svg";
import Config from "./../Config";

const APP_STORE =
  "https://apps.apple.com/ch/app/gen-c3-a8ve-en-c3-a9t-c3-a9/id1387220355";
const PLAY_STORE = "https://play.google.com/store/apps/details?id=app.geneveenete.ch";

export function AppStoreBadge() {
  return (
    <a
      className="app-store"
      href={APP_STORE}
      title={`Download ${Config.name}!`}
    >
      <img src={AppStoreSrc} alt={`Download ${Config.name}`} />
    </a>
  );
}

export function GooglePlayStoreBadge() {
  return (
    <a
      className="google-play-store"
      href={PLAY_STORE}
      title={`Download ${Config.name}!`}
    >
      <img src={GoogleStoreSrc} alt={`Download ${Config.name}`} />
    </a>
  );
}
