function translate(children, language) {
  let text = children;

  if (typeof children === "object") {
    try {
      text = children[language];
      if (text === undefined) {
        text = children["fr"];
      }
    } catch (error) {
      text = children;
    }
  }
  return text;
}

export default translate;
