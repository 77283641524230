import React, {useState } from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MenuOutlined, CalendarFilled, CloseOutlined } from "@ant-design/icons";

import Config from "./../Config";
import LogoSrc from "../Images/geeLogo.png";
import { AppStoreBadge, GooglePlayStoreBadge } from "./Badges";
import CalendarContainer from "../Containers/CalendarContainer";
import AboutSection from "./Footer";

const DOWNLOAD_EN = "Download the app";
const DOWNLOAD_FR = "Télécharger l'application";

function Logo({ className }) {
  return (
    <div className="__LOGO__">
      <Link to="/">
        <img className={className} src={LogoSrc} alt="Home" />
      </Link>
    </div>
  );
}

function MobileHamberger({ activeLanguage, changeLanguage }) {
  return (
    <div className="__MOBILE_HAMBERGER__">
      <Row>
        <Col span={24}>
          <div className="download-app-text">
            <p>{DOWNLOAD_FR}</p>
            <p>{DOWNLOAD_EN}</p>
          </div>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <div>
                <AppStoreBadge />
              </div>
              <div>
                <GooglePlayStoreBadge />
              </div>
            </Col>
            <Col span={6}>
              <div className="language-section">
                <Button
                  disabled={activeLanguage === "fr"}
                  onClick={() => changeLanguage("fr")}
                >
                  FR
                </Button>
                <Button
                  disabled={activeLanguage === "en"}
                  onClick={() => changeLanguage("en")}
                >
                  EN
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AboutSection />
        </Col>
      </Row>
    </div>
  );
}

function MobileCalendar() {
  return (
    <div className="__MOBILE_CALENDAR__">
      <CalendarContainer />
    </div>
  );
}

export function Header() {
  const { i18n } = useTranslation();

  const [showMenu, setMenu] = useState(false);
  const [showCalendar, setCalendar] = useState(false);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };


  return (
    <div className="__HEADER__ ">
      <div className="hide-on-small-screens menu-desktop-wrapper">
        <div className="menu-desktop">
          <Row justify="start" align={"start"}>
            <Col>
              <div className="logo">
                <Logo className="logo" />
              </div>
            </Col>
            <Col className="logoText">
              <Link to="/">{Config.name}</Link>
            </Col>
            <Col flex={5}>
              <Row className="download-app" align={"middle"} justify={"center"}>
                <Col>
                  <p>{DOWNLOAD_FR}</p>
                  <p>{DOWNLOAD_EN}</p>
                </Col>
                <Col>
                  <AppStoreBadge />
                  <GooglePlayStoreBadge />
                </Col>
              </Row>
            </Col>
            <Col flex={3}>
              <div className="change-language">
                <button
                  className={i18n.language === "fr" ? "active-language" : null}
                  onClick={() => changeLanguage("fr")}
                >
                  fr
                </button>
                <button
                  className={i18n.language === "en" ? "active-language" : null}
                  onClick={() => changeLanguage("en")}
                >
                  en
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="show-on-small-screens">
        <Row justify="start" align={"start"}>
          <Col flex={1}>
            <div className="logo">
              <Logo className="logo-mobile" />
            </div>
          </Col>
          <Row justify="end" align="middle">
            <Col flex={0}>
              <Button
                onClick={() => {
                  setCalendar(showCalendar ? false : true);
                  setMenu(false);
                }}
                style={{
                  width: 64,
                  height: 64,
                  borderRadius: 0,
                  backgroundColor: "#ec6173",
                  border: 0
                }}
                size="large"
                type="primary"
                icon={
                  showCalendar ? (
                    <CloseOutlined style={{ fontSize: 24, color: "#fff" }} />
                  ) : (
                    <CalendarFilled style={{ fontSize: 24, color: "#fff" }} />
                  )
                }
              />
            </Col>
            <Col flex={0}>
              <Button
                onClick={() => {
                  setMenu(showMenu ? false : true);
                  setCalendar(false);
                }}
                style={{
                  width: 64,
                  borderRadius: 0,
                  height: 64,
                  backgroundColor: "#000",
                  border: 0
                }}
                size="large"
                type="primary"
                icon={
                  showMenu ? (
                    <CloseOutlined style={{ fontSize: 24, color: "#fff" }} />
                  ) : (
                    <MenuOutlined style={{ fontSize: 24, color: "#fff" }} />
                  )
                }
              />
            </Col>
          </Row>
        </Row>
        {showMenu && (
          <MobileHamberger
            activeLanguage={i18n.language}
            changeLanguage={changeLanguage}
          />
        )}
        {showCalendar && <MobileCalendar />}
      </div>
    </div>
  );
}
