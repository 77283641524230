import {
  RESET_DATE,
  CHANGE_DATE,
  PREVIOUS_DATE,
  NEXT_DATE,
  CHANGE_LANGUAGE_EN,
  CHANGE_LANGUAGE_FR
} from "./actionsTypes";
import { nextDay, previousDay, getCurrentDate } from "./Utils/dates";

export function changeDate(date) {
  return {
    type: CHANGE_DATE,
    payload: {
      date
    }
  };
}

export function resetDate() {
  const date = getCurrentDate();
  return {
    type: RESET_DATE,
    payload: {
      date
    }
  };
}

export function changeNextDate(date) {
  return {
    type: NEXT_DATE,
    payload: {
      date: nextDay(date)
    }
  };
}

export function changePreviousDate(date) {
  return {
    type: PREVIOUS_DATE,
    payload: {
      date: previousDay(date)
    }
  };
}

export function changeLanguageEnglish() {
  return {
    type: CHANGE_LANGUAGE_EN,
    payload: { language_code: "en" }
  };
}
export function changeLanguageFrench() {
  return {
    type: CHANGE_LANGUAGE_FR,
    payload: { language_code: "fr" }
  };
}
