// const Config = {
//   name: "Genève en été",
//   hackCurrentDate: null,
//   minDate: "2020-07-01",
//   maxDate: "2020-10-31",
//   dateFormat: "YYYY-MM-DD",
//   timeFormat: "HH:mm",
//   dateNavigationFormat: "dd D MMMM YYYY",
//   dateCalendarFormat: "MMMM",
//   apiServer: "https://admin.geneveenete.ch/api"
//   // apiServer: "http://localhost:8000/api"
// };
// export default Config;

const MIN_DATE = '2020-07-01';
const MAX_DATE = '2022-09-30';

export default {
  API_SERVER: 'https://api.openagenda.com/v2/agendas/83618556',
  // API_SERVER: 'https://openagenda.com/agendas/83618556',
  minDate: MIN_DATE,
  maxDate: MAX_DATE,
  hackStartingDate: null,
  version: '4.1.0.18',
  reducerVersion: '0.0.6',
  API_KEY: '039c78ea2df44f4ca63b77b422734c5e',
  API_RESULT_SIZE: 200,
  name: "Genève en été",
  hackCurrentDate: null,
  dateFormat: "YYYY-MM-DD",
  timeFormat: "HH:mm",
  dateNavigationFormat: "dd D MMMM YYYY",
  dateCalendarFormat: "MMMM",
};
