import { connect } from "react-redux";

import { changeNextDate, changePreviousDate, resetDate } from "../actions";
import DateNavigation from "../Components/DateNavigation";

const mapStateToProps = state => {
  return {
    viewingDate: state.date.viewingDate
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleChangeNextDate: date => dispatch(changeNextDate(date)),
    handleChangePreviousDate: date => dispatch(changePreviousDate(date)),
    handleChangeResetDate: _ => dispatch(resetDate())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DateNavigation);
