import React from "react";
import { Row, Col } from "antd";

export default function AboutSection() {
  return (
    <div className="__CREDITS__">
      <Row>
        <Col className="credits" span={24}>
          <Row>
            <Col span={24}>
              <div className="pfl-association">
                <h4>Service Culturel, Ville de Genève</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="credit-section">
                <h4>Identité</h4>
                <p>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.basedesign.com/geneva"
                    target="_blank"
                    title="BASE Design"
                  >
                    BASE Design
                  </a>
                </p>
              </div>
            </Col>
            <Col span={24}>
              <div className="credit-section credit-section-with-height">
                <h4>Développement</h4>
                <p>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.agencegardeners.com"
                    target="_blank"
                    title="agencegardeners"
                  >
                    GARDENERS
                  </a>
                  {' '} / {' '}
                  <a
                    rel="noopener noreferrer"
                    href="https://giogos.ch"
                    target="_blank"
                    title="GIOGOS"
                  >
                    GIOGOS
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
